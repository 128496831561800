import {
  Pagination,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import React, { memo, ReactNode } from "react";

import Loader from "components/Common/Loader";
import NoData from "components/Common/NoData";
import { TableExportIcon } from "static/icons/TableSortIcon";

const AppTable = ({
  anchorEl = null,
  handlePopoverClose,
  popoverContent,
  tableHead,
  sortingHeadItems,
  handlePopoverOpen,
  actions,
  meta,
  page,
  changePageHandler,
  rows,
  rowIdentifiers,
  isLoading,
  actionsHeading = "",
}: AppTableProps) => {
  const classes = useStyle();
  const tableColumn = (title: string) => {
    return (
      <Typography
        variant="body1"
        fontSize={14}
        fontWeight={400}
        color="#252733"
        whiteSpace="nowrap"
      >
        {title}
      </Typography>
    );
  };
  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {popoverContent}
      </Popover>
      <TableContainer>
        <div className="d-flex mt-31 direction-col">
          <Table>
            <TableHead>
              <TableRow>
                {tableHead.map((el) =>
                  sortingHeadItems?.length &&
                  sortingHeadItems.some((item) => item === el.title) ? (
                    <TableHeaderCell key={el.key}>
                      <TableSortLabel
                        onClick={handlePopoverOpen}
                        IconComponent={() => <>{TableExportIcon}</>}
                      >
                        {tableColumn(el.title)}
                      </TableSortLabel>
                    </TableHeaderCell>
                  ) : (
                    <TableHeaderCell key={el.key}>
                      {tableColumn(el.title)}
                    </TableHeaderCell>
                  )
                )}
                {actions && (
                  <TableHeaderCell align="left">
                    {actionsHeading}
                  </TableHeaderCell>
                )}
              </TableRow>
            </TableHead>
            {!isLoading && (
              <TableBody>
                {rows.map((row: DynamicObject) => (
                  <TableRow
                    key={row.userId}
                    hover
                    id={row.userId?.toString()}
                    className={classes.tableRow}
                  >
                    {rowIdentifiers.map((elem) => {
                      return (
                        <TableBodyCell key={elem}>{row[elem]}</TableBodyCell>
                      );
                    })}
                    {actions && (
                      <TableBodyCell align="center">
                        {actions(row)}
                      </TableBodyCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          {isLoading ? <Loader size={50} /> : !rows.length ? <NoData /> : null}
          {meta && meta?.total_pages > 1 && (
            <Pagination
              className={classes.tablePagination}
              count={meta?.total_pages ?? 0}
              shape="rounded"
              page={page}
              defaultPage={1}
              onChange={(e, page) =>
                changePageHandler && changePageHandler(page)
              }
            />
          )}
        </div>
      </TableContainer>
    </>
  );
};

export default memo(AppTable);

const useStyle = makeStyles({
  tableRow: {
    "&:hover": {
      backgroundColor: "white !important",
      boxShadow: "0px 4px 4px rgb(0 0 0 / 16%)",
    },
  },
  headerSubColumns: {
    fontFamily: "Poppins !important",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "16px",
    color: "#52575C !important",
  },
  headerSubColumnList: {
    borderBottom: "1px solid #ECECEC",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "white !important",
    },
  },
  tablePagination: {
    dispaly: "flex",
    justifyContent: "flex-end",
    marginTop: 10,
  },
});

const TableBodyCell = withStyles((theme) => ({
  root: {
    color: "#52575C !important",
    fontSize: "12px !important",
    fontFamily: "Poppins !important",
    whiteSpace: "nowrap",
  },
}))(TableCell);
const TableHeaderCell = withStyles((theme) => ({
  root: {
    color: "#252733 !important",
    fontSize: "14px !important",
    fontWeight: 500,
    fontFamily: "Poppins",
    wordSpacing: "nowrap",
  },
}))(TableCell);

interface metaProps {
  current_page: number;
  next_page: null | number;
  per_page: number;
  previous_page: null | number;
  total_items: number;
  total_pages: number;
}

type withMeta = {
  meta: metaProps;
  changePageHandler: (page: number) => void;
  page: number;
};
type withOutMeta = {
  meta?: never;
  changePageHandler?: never;
  page?: never;
};
type Meta = withMeta | withOutMeta;

interface Props {
  anchorEl?: HTMLButtonElement | null;
  handlePopoverClose?: () => void;
  popoverContent?: ReactNode;
  tableHead: DynamicObject[];
  sortingHeadItems?: Array<string>;
  handlePopoverOpen?: (e: React.SyntheticEvent) => void;
  actions?: (row: DynamicObject) => ReactNode;
  rows: DynamicObject[];
  rowIdentifiers: Array<string>;
  isLoading?: boolean;
  actionsHeading?: string;
}

type AppTableProps = Meta & Props;
