import { Grid } from "@mui/material";
import { memo } from "react";
import { styled } from "@mui/material/styles";

import { useAuthContext } from "userContext/AuthContext";
import { NoUserData } from "static/icons/NoUserData";
import { NoDataSvg } from "static/icons/NoData";

const NoData = () => {
  const { authUser } = useAuthContext();

  return (
    <Grid>
      <Container container alignItems="center" justifyContent="center">
        {authUser?.role === 2 ? <NoUserData /> : <NoDataSvg />}
        <Wrapper>NO DATA AVAILABLE</Wrapper>
      </Container>
    </Grid>
  );
};

export default memo(NoData);

const Container = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  marginTop: 50,
}));
const Wrapper = styled("div")(({ theme }) => ({
  fontSize: 20,
  fontWeight: "bold",
  padding: "5px 20px",
  marginTop: 10,
}));
