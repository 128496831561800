export const initialState = {
  user: {}
};

export const userReducer = (
  state: UserState = initialState,
  action: UserAction
) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.payload
      };
  }
};

interface UserState {
  user: DynamicObject;
}

type UserAction = { type: 'SET_USER'; payload: DynamicObject };
