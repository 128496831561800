import { Typography } from '@mui/material';
import { memo } from 'react';

const InfoLabel = ({ title, inputLabelClass, required }) => {
  return (
    <Typography className={inputLabelClass} fontSize='14px' color='#FFFFFF'>
      {required ? (
        <>
          {title}
          <span style={{ color: 'red' }}>*</span>
        </>
      ) : (
        title
      )}
    </Typography>
  );
};
export default memo(InfoLabel);
