import { useAppMutation } from 'hooks/useAppMutation';
import { useDisplaySuccess } from 'hooks/useDisplaySuccess';
import { useGetPaginatedData } from 'hooks/useGetPaginatedData';
import { ApiMethods, Apis } from 'static/apis';

export const useGetSections = ({
  page,
  perPage,
  onSuccess
}: GetSectionsParams) => {
  let url = Apis.sections + '?';
  if (page) url += `page=${page}`;
  if (perPage) url += `&records_per_page=${perPage}`;

  const response = useGetPaginatedData(
    Apis.sections,
    url,
    { page, perPage },
    { onSuccess }
  );
  const list = response.data?.data?.data ? response.data?.data?.data : [];
  const meta = response.data?.data?.meta ? response.data?.data?.meta : [];

  return { list, meta, response };
};
export const useAddSection = (param: AddSectionParams) => {
  const display = useDisplaySuccess();

  return useAppMutation(
    Apis.section,
    ApiMethods.POST,
    [Apis.sections],
    data => {
      if (!data.data) return;
      display('Section added successfully');
      param.onSuccess?.(data?.data);
    }
  );
};

export const useUpdateSection = (param: AddSectionParams) => {
  const display = useDisplaySuccess();

  return useAppMutation(Apis.section, ApiMethods.PUT, [Apis.sections], data => {
    if (!data.data) return;
    display('Section updated successfully');
    param?.onSuccess?.(data?.data);
  });
};

export const useDeleteSection = ({ onSuccess }: useDeleteSectionParams) => {
  const display = useDisplaySuccess();

  return useAppMutation(
    Apis.section,
    ApiMethods.DELETE,
    [Apis.sections],
    remove => {
      display(remove.data?.message ?? 'Section deleted successfuly');
      onSuccess();
    }
  );
};

interface GetSectionsParams {
  page: number;
  perPage: number;
  onSuccess?: (data: DynamicObject) => void;
}

interface AddSectionParams {
  onSuccess?: (data: DynamicObject) => void;
}

interface useDeleteSectionParams {
  onSuccess: () => void;
}
