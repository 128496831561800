import { memo, Suspense } from 'react';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { LayoutProps } from 'interfaces';
import DashboardHeader from 'components/shared/DashboardHeader';
import DashboardSideBar from 'components/shared/DashboardSideBar';

const Layout = ({ children }: LayoutProps) => {
  const classes = useStyles();

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Box component='main' className={classes.MainContainer}>
          <Grid container>
            <Grid item xs={2}>
              <DashboardSideBar />
            </Grid>
            <Grid item xs={10}>
              <DashboardHeader />
              <Suspense fallback={<div />}>{children}</Suspense>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default memo(Layout);

const useStyles = makeStyles({
  MainContainer: {
    overflow: 'hidden',
    backgroundColor: '#f5f5f5',
    flexGrow: 1,
    minHeight: '100vh'
  }
});
