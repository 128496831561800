import { memo, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { links } from 'static/links';
import AdminLayout from 'components/Layout/AdminLayout';

const AdminOutlet = () => {
  const isAuth = true;
  const userRole = { role: 'admin' };
  return isAuth ? (
    <Suspense fallback='loading....'>
      {userRole.role === 'admin' ? (
        <AdminLayout>
          <Outlet />
        </AdminLayout>
      ) : (
        <Navigate to={links.notFound} />
      )}
    </Suspense>
  ) : (
    <Navigate to={links.login} />
  );
};
export default memo(AdminOutlet);
