import { CircularProgress, Grid } from '@mui/material';
import { Container } from '@mui/system';
import React, { memo } from 'react';

function Loader({ size }: LoaderProps) {
  return (
    <Container maxWidth='lg' sx={{ mb: 4, mt: 4 }}>
      <Grid container>
        <Grid
          item
          textAlign='center'
          display={'flex'}
          justifyContent={'center'}
          xs={12}
        >
          <CircularProgress size={size} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default memo(Loader);

interface LoaderProps {
  size: number;
}
