import { createTheme, Theme } from '@mui/material';

const defaultTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 425,
      md: 860,
      lg: 1280,
      xl: 2560
    }
  }
});
export const AppTheme = (theme: Theme) =>
  createTheme({
    breakpoints: {
      values: {
        ...defaultTheme.breakpoints.values
      }
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true // No more ripple, on the whole application 💣!
        }
      },

      MuiInput: {
        styleOverrides: {
          root: {
            '&:hover': {
              borderBottom: 'none'
            }
          }
        }
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            background: 'none',
            border: 'none',
            boxShadow: 'none',
            '&:before': {
              backgroundColor: 'transparent !important'
            }
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontSize: '40px',
            fontWeight: 600,
            color: '#FFFFFF',
            fontFamily: 'Poppins'
          },
          body1: {
            fontSize: '20px',
            fontWeight: 700,
            color: '#383838',
            fontFamily: 'manrope'
          },
          body2: {
            fontSize: '16px',
            fontWeight: 400,
            color: '#52575C',
            fontFamily: 'Poppins'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            fontFamily: 'Poppins',
            fontWeight: 500,
            textTransform: 'none'
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: '10px',
            fontSize: '12px',
            '&:hover': {
              backgroundColor: '#383838',
              color: 'white'
            }
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: '14px !important',
            fontWeight: 400,
            color: '#999999',
            fontFamily: 'Poppins',
            [theme.breakpoints.down('md')]: {
              padding: '12px 9px !important',
              fontSize: 12
            }
          }
        }
      }
    },
    palette: {
      error: {
        main: '#ef5350'
      },
      primary: {
        main: '#383838'
      },
      secondary: {
        main: '#232426'
      },
      success: {
        main: '#29CC39'
      }
    },
    typography: {
      fontFamily: ['Poppins'].join(',')
    }
  });
