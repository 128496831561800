import { memo, Suspense } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { PublicLayoutProps } from 'interfaces';
import Header from 'components/shared/Header';

const PublicLayout = ({ children }: PublicLayoutProps) => {
  const classes = useStyles();

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Box component='main' className={classes.MainContainer}>
          <Header />
          <Suspense fallback={<div />}>{children}</Suspense>
        </Box>
      </Box>
    </>
  );
};

export default memo(PublicLayout);

const useStyles = makeStyles({
  MainContainer: {
    overflow: 'hidden',
    backgroundColor: 'white',
    flexGrow: 1,
    minHeight: '100vh'
  }
});
