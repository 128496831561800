import {
  Breakpoint,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Slide,
  Stack,
  Typography
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { memo, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';

const AppDialog = ({
  handleClose,
  open,
  title,
  children,
  classes,
  footer,
  customBottons,
  maxWidth,
  titleBorder = false,
  disabledPadding = false,
  headerBackground = '',
  sx = {}
}: AppDialogProps) => {
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    setScroll('body');
  }, [location]);

  return (
    <div>
      <Dialog
        maxWidth={maxWidth}
        keepMounted
        TransitionComponent={Transition}
        aria-describedby='alert-dialog-slide-description'
        onClose={handleClose}
        open={open}
        scroll={scroll}
        classes={{
          paper: disabledPadding
            ? classes.dialogPaperWithoutPadding
            : classes.dialogPaper,
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody
        }}
        sx={sx}
      >
        <DialogTitle
          borderBottom={titleBorder ? '1px solid #DBDDE0' : 'none'}
          sx={{
            background: headerBackground ? headerBackground : ''
          }}
        >
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Typography fontSize={18} fontWeight={600}>
              {title}
            </Typography>
            {customBottons ? customBottons : null}
          </Stack>
        </DialogTitle>
        <DialogContent
          sx={{
            height: 'auto',
            padding: disabledPadding ? '0px' : '20px 24px'
          }}
        >
          {children}
        </DialogContent>
        {footer}
      </Dialog>
    </div>
  );
};

const styles = {
  dialogPaper: {
    width: 'auto',
    height: 'auto',
    padding: '10px'
  },
  dialogPaperWithoutPadding: {
    width: 'auto',
    height: 'auto',
    padding: '0px !important'
  },
  MuiDialogContent: {
    height: 100
  },
  topScrollPaper: {
    alignItems: 'flex-start'
  },
  topPaperScrollBody: {
    verticalAlign: 'top'
  }
};
export default withStyles(styles)(memo(AppDialog));

interface AppDialogProps {
  sx?: DynamicObject;
  handleClose: () => void;
  open: boolean;
  title: string | React.ReactElement;
  children: React.ReactElement;
  classes: any;
  footer?: JSX.Element;
  customBottons?: JSX.Element;
  maxWidth?: false | Breakpoint;
  titleBorder?: boolean;
  disabledPadding?: boolean;
  headerBackground?: string;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide ref={ref} direction='up' {...props} />;
});
