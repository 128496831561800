import React from 'react';
import { Controller } from 'react-hook-form';
import { Grid, TextField } from '@mui/material';
import InputMask from 'react-input-mask';

import InfoLabel from './InfoLabel';

function AppInputField({
  disabled = false,
  grid = 6,
  control,
  name,
  type = 'text',
  variant = 'outlined',
  placeholder,
  required = false,
  inputTopLabel = '',
  bottomLabel = false,
  mask = false,
  inputFieldClass,
  inputProps = {},
  inputLabelClass = '',
  endAdornment = {},
  multiline = false
}) {
  return (
    <Grid item sm={grid}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div>
            {mask ? (
              <>
                <InputMask mask={mask} value={value} onChange={onChange}>
                  {inputProps => (
                    <div>
                      {inputTopLabel.length && (
                        <InfoLabel
                          title={inputTopLabel}
                          inputLabelClass={inputLabelClass}
                          required={required}
                        />
                      )}
                      <TextField
                        error={!!error}
                        fullWidth={true}
                        helperText={error ? error.message : null}
                        onChange={onChange}
                        required={required}
                        value={value}
                        InputLabelProps={{ shrink: true }}
                        type={type}
                        variant={variant}
                        placeholder={placeholder}
                        disabled={disabled}
                        id={type}
                        multiline={multiline}
                        {...inputProps}
                      />
                      {bottomLabel && <InfoLabel title={bottomLabel} />}
                    </div>
                  )}
                </InputMask>
              </>
            ) : (
              <div>
                {inputTopLabel && (
                  <InfoLabel
                    title={inputTopLabel}
                    inputLabelClass={inputLabelClass}
                    required={required}
                  />
                )}
                <TextField
                  multiline={multiline}
                  className={inputFieldClass}
                  error={!!error}
                  fullWidth={true}
                  helperText={error ? error.message : null}
                  inputProps={inputProps}
                  onChange={onChange}
                  required={required}
                  value={value}
                  InputLabelProps={{ shrink: true }}
                  type={type}
                  variant={variant}
                  placeholder={placeholder}
                  disabled={disabled}
                  InputProps={endAdornment}
                />
                {bottomLabel && <InfoLabel title={bottomLabel} />}
              </div>
            )}
          </div>
        )}
      />
    </Grid>
  );
}

export default AppInputField;
