import * as React from 'react';
import { Box, Typography, Modal } from '@mui/material';
import { Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';

const ConfirmationModal = ({
  title,
  description,
  open,
  actionButtons
}: ConfirmationModalProps) => {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Stack direction={'column'} spacing={3}>
        <Box sx={style}>
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'
            className={classes.modalHeading}
          >
            {title}
          </Typography>
          <Typography
            id='modal-modal-description'
            className={classes.modalDescription}
          >
            {description}
          </Typography>
          {actionButtons}
        </Box>
      </Stack>
    </Modal>
  );
};

export default React.memo(ConfirmationModal);

interface ConfirmationModalProps {
  title: string;
  description: string | React.ReactElement;
  open: boolean;
  actionButtons: React.ReactElement;
}
const useStyles = makeStyles({
  cancelBtn: {
    padding: '6px 10px !important',
    fontSize: '14px !important',
    color: '#52575C !important',
    border: '1px solid #DBDDE0 !important',
    background: 'white !important',
    borderRadius: 4
  },
  deleteBtn: {
    background: '#B50000 !important',
    borderRadius: 4,
    padding: '6px 10px !important',
    fontSize: '14px !important',
    color: '#FFFFFF !important'
  },
  modalFooter: {
    float: 'right',
    marginTop: '40px'
  },
  modalHeading: {
    fontSize: '24px !important',
    fontWeight: '600 !important',
    fontFamily: 'Poppins !important'
  },
  modalDescription: {
    marginTop: '16px !important',
    fontSize: '14px !important',
    fontWeight: '400 !important',
    color: '#52575C !Important'
  }
});
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 497,
  bgcolor: 'background.paper',
  borderRadius: 5,
  boxShadow: 24,
  p: 4
};
