import { useSnackbar } from 'notistack';

export const useDisplayError = () => {
  const { enqueueSnackbar } = useSnackbar();

  const errorHandler = (message: unknown) => {
    const err: string = Array.isArray(message) ? message[0] : message;
    message && enqueueSnackbar(err, { variant: 'error' });
  };
  return errorHandler;
};
