import {
  AppBar,
  Button,
  Grid,
  Toolbar,
  Typography,
  Theme,
  Avatar,
  Box,
} from "@mui/material";
import React, { memo } from "react";
import { makeStyles } from "@mui/styles";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";

import logo from "static/images/WakeelLogo.png";
import AppMenu from "components/Common/AppMenu";
import { links } from "static/links";
import { COLOR_BLACK, FONT_14 } from "utils/static";
import { useAuthContext } from "userContext/AuthContext";

const Header = () => {
  const { authUser, clearAuth } = useAuthContext();
  const navigate = useNavigate();
  const classes = useStyles();
  const [caseAnchorEl, setCaseAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const [LawyerAnchorEl, setLawyerAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const openCaseMenu = Boolean(caseAnchorEl);
  const openLawyersMenu = Boolean(LawyerAnchorEl);

  const handleLawyerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLawyerAnchorEl(event.currentTarget);
  };
  const handleLawyerClose = () => {
    setLawyerAnchorEl(null);
  };

  const handleCaseClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCaseAnchorEl(event.currentTarget);
  };
  const handleCaseClose = () => {
    setCaseAnchorEl(null);
  };
  const handleLogout = () => {
    clearAuth();
  };
  const handleProfileClick = () => {
    if (authUser?.role === 1) {
      navigate(links.adminDashboard);
    } else navigate(links.dashboard);
  };
  return (
    <AppBar position="static">
      <Toolbar
        variant="regular"
        className={classes.LandingPageHeaderContainer}
        disableGutters
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={4}
            sm={2}
            md={3}
            lg={4}
            alignItems="center"
            display="flex"
          >
            <img
              src={logo}
              alt="logo"
              className={classes.LandingPageHeaderLogo}
            />
            <Typography variant="body1">WAKEEL</Typography>
          </Grid>
          <Grid item xs={5} sm={6} md={5} alignItems="center" display="flex">
            <Stack
              spacing={{ xs: 4, md: 2, lg: 4 }}
              direction="row"
              alignItems="center"
            >
              <div className={classes.activeMenu}>
                <Typography className={classes.activeText} variant="body2">
                  Home
                </Typography>
              </div>

              <AppMenu
                id={"basic-Cases"}
                open={openCaseMenu}
                menuId={"basic-case-menu"}
                handleClick={handleCaseClick}
                anchorEl={caseAnchorEl}
                handleClose={handleCaseClose}
                title={"Cases"}
              />
              <AppMenu
                id={"basic-Lawyers"}
                open={openLawyersMenu}
                menuId={"basic-Lawyers-menu"}
                handleClick={handleLawyerClick}
                anchorEl={LawyerAnchorEl}
                handleClose={handleLawyerClose}
                title={"Lawyers"}
              />
              <Typography variant="body2">Blogs</Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={3}
            sm={4}
            md={4}
            lg={3}
            alignItems={{ xs: "center", md: "flex-end", lg: "center" }}
            justifyContent={{ xs: "center", md: "flex-end", lg: "center" }}
            display="flex"
          >
            <Stack direction="row" spacing={3}>
              {authUser && authUser.id ? (
                <>
                  <Box
                    display="flex"
                    gap="10px"
                    alignItems="center"
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={handleProfileClick}
                  >
                    <Avatar
                      alt={authUser?.first_name?.toUpperCase()}
                      src={
                        process.env.REACT_APP_BASE_IMAGE +
                        authUser?.profile_image
                      }
                    />
                    <Typography variant="body2">
                      {authUser.first_name}
                    </Typography>
                  </Box>
                  <Button variant="outlined" onClick={handleLogout}>
                    Logout
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    className={classes.registerLawyerBtn}
                    onClick={() => navigate(links.register)}
                  >
                    Register as lawyer
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => navigate(links.login)}
                  >
                    Login
                  </Button>
                </>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default memo(Header);

const useStyles = makeStyles((theme: Theme) => ({
  LandingPageHeaderContainer: {
    backgroundColor: "#FFFFFF",
    padding: "10px 80px",
    [theme.breakpoints.down("lg")]: {
      padding: "10px 5px !important",
    },
    [theme.breakpoints.down("md")]: {
      padding: "10px 5px !important",
    },
  },
  LandingPageHeaderLogo: {
    height: "52px",
    width: "53px",
    marginRight: "18px",
  },
  headerMenu: {
    padding: "10px",
    fontSize: "12px",
  },
  registerLawyerBtn: {
    height: "50.34px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "11px !important",
    },
  },
  activeMenu: {
    height: "37px",
    borderBottom: "2px solid #383838",
    width: "55px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  activeText: {
    color: "#383838 !important",
  },
  UserName: {
    ...COLOR_BLACK,
    ...FONT_14,
  },
}));
