import { lazy, memo } from 'react';
import { CssBaseline } from '@mui/material';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/styles';

import Auth from 'components/Auth';
import { auth, links } from 'static/links';
import { AppTheme } from 'styles/AppTheme';
import ErrorBoundary from './ErrorBoundary';
import NotFound from './NotFound';
import PrivateOutlet from './PrivateOutlet';
import Scroller from './Scroller';
import PublicOutlet from './PublicOutlet';
import AdminOutlet from './AdminOutlet';
import { AuthContextProvider } from 'userContext/AuthContext';
import AdminSections from 'components/Main/AdminDashboard/AdminSections';

// PUBLIC ROUTES
const Login = lazy(() => import('components/Auth/Login'));
const Register = lazy(() => import('components/Auth/Register'));
const Landing = lazy(() => import('components/Landing'));
const Search = lazy(() => import('components/Landing/Search'));
const CreatePassword = lazy(() => import('components/Auth/CreatePassword'));
const ForgotPassword = lazy(() => import('components/Auth/ForgotPassword'));
const ResetPassword = lazy(() => import('components/Auth/ResetPassword'));
const Plans = lazy(() => import('components/Auth/Plans'));

// PRIVATE ROUTES
const AdminDashboard = lazy(() => import('components/Main/AdminDashboard'));
const AdminCaseType = lazy(
  () => import('components/Main/AdminDashboard/CaseType')
);
const AdminSpecialization = lazy(
  () => import('components/Main/AdminDashboard/AdminSpecialization')
);
const AdminTemplates = lazy(
  () => import('components/Main/AdminDashboard/AdminTemplates')
);
const AdminPlans = lazy(
  () => import('components/Main/AdminDashboard/AdminPlans')
);
const AdminCourts = lazy(
  () => import('components/Main/AdminDashboard/AdminCourts')
);

const AdminCampaigns = lazy(
  () => import('components/Main/AdminDashboard/AdminCampaign')
);

const AdsRequests = lazy(
  () => import('components/Main/AdminDashboard/AdsRequests')
);

const UserDashboard = lazy(() => import('components/Main/UserDashboard'));
const UserProfile = lazy(
  () => import('components/Main/UserDashboard/UserProfile')
);
const UserCaseManagement = lazy(
  () => import('components/Main/UserDashboard/UserCaseManagement')
);
const UserCaseDetails = lazy(
  () => import('components/Main/UserDashboard/UserCaseDetail')
);

const AppRoutes = () => (
  <ErrorBoundary>
    <BrowserRouter>
      <Scroller>
        <ThemeProvider theme={AppTheme}>
          <CssBaseline />
          <AuthContextProvider>
            <Routes>
              <Route element={<PublicOutlet />} path={links.landing}>
                <Route index element={<Landing />} />
                <Route index element={<Search />} path={links.landingSearch} />
              </Route>

              <Route element={<PrivateOutlet />} path={links.dashboard}>
                <Route index element={<UserDashboard />} />
                <Route
                  element={<UserProfile />}
                  path={links.dashboardProfile}
                />
                <Route
                  element={<UserCaseManagement />}
                  path={links.dashboardCaseMangement}
                />
                <Route
                  element={<UserCaseDetails />}
                  path={links.dashboardCaseDetails}
                />
              </Route>

              <Route element={<AdminOutlet />} path={links.adminDashboard}>
                <Route index element={<AdminDashboard />} />
                <Route
                  element={<AdminCaseType />}
                  path={links.adminCaseTypes}
                />
                <Route
                  element={<AdminSpecialization />}
                  path={links.adminSpecialization}
                />
                <Route
                  element={<AdminTemplates />}
                  path={links.adminTemplates}
                />
                <Route element={<AdminPlans />} path={links.adminPlans} />
                <Route element={<AdminSections />} path={links.adminSections} />
                <Route element={<AdminCourts />} path={links.adminCourts} />
                <Route
                  element={<AdminCampaigns />}
                  path={links.adminCampaigns}
                />
                <Route element={<AdsRequests />} path={links.adsRequests} />
              </Route>

              <Route element={<Auth />} path={auth}>
                <Route element={<Login />} path={links.login} />
                <Route element={<Register />} path={links.register} />
                <Route
                  element={<ForgotPassword />}
                  path={links.forgotPassword}
                />
                <Route element={<ResetPassword />} path={links.resetPassword} />
                <Route
                  element={<CreatePassword />}
                  path={links.createPassword}
                />
                <Route element={<Plans />} path={links.plans} />
                <Route
                  element={<Navigate replace to={links.login} />}
                  path={links.notFound}
                />
              </Route>
              <Route element={<NotFound />} path={links.notFound} />
            </Routes>
          </AuthContextProvider>
        </ThemeProvider>
      </Scroller>
    </BrowserRouter>
  </ErrorBoundary>
);
export default memo(AppRoutes);
