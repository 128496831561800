import DashboardLogo from 'static/images/dashboardLogo.png';
import ActiveDashboardLogo from 'static/images/DashboardActiveLogo.png';
import ProfileLogo from 'static/images/ProfileLogo.png';
import ProfileActiveLogo from 'static/images/ProfileActiveLogo.png';

import CaseManagementLogo from 'static/images/CaseLogo.png';
import CaseManagementActiveLogo from 'static/images/CaseLogo.png';

import CalenderLogo from 'static/images/CalenderLogo.png';
import CalenderActiveLogo from 'static/images/CalenderActiveLogo.png';

import TemplatesLogo from 'static/images/TemplateLogo.png';
import TemplateActiveLogo from 'static/images/TemplateActiveLogo.png';

import AddsLogo from 'static/images/AddsLogo.png';
import AddsActiveLogo from 'static/images/AddsActiveLogo.png';

import SettingsLogo from 'static/images/SettingsLogo.png';
import SettingsActiveLogo from 'static/images/SettingsActiveLogo.png';

import CaseTypeLogo from 'static/images/CaseTypeLogo.png';
import ActiveCaseType from 'static/images/ActiveCaseType.png';

import SpecializationLogo from 'static/images/SpecializationLogo.png';
import ActiveSpecializationLogo from 'static/images/SpecializationActiveLogo.png';

import PlansLogo from 'static/images/PlansLogo.png';
import ActivePlansLogo from 'static/images/ActivePlansLogo.png';

import AddsRequestLogo from 'static/images/AddsRequestLogo.png';
import AddsRequestActiveLogo from 'static/images/AddsRequestActiveLogo.png';

import CompaignsLogo from 'static/images/CompaignsLogo.png';
import ActiveCompaignsLogo from 'static/images/ActiveCompaignsLogo.png';

import DashboardCourtsLogo from 'static/images/DashboardCourtsLogo.png';
import DashboardActiveCourtsLogo from 'static/images/DashboardActiveCourtsLogo.png';

export const auth = '/auth';
export const dashboard = '/dashboard';
export const admin = '/admin';

export const links = {
  landing: '/',
  landingSearch: '/lawyerSearch',
  login: `${auth}/login`,
  register: `${auth}/register`,
  createPassword: `${auth}/create-password`,
  forgotPassword: `${auth}/forgot-passowrd`,
  resetPassword: `${auth}/reset-password`,
  dashboard: dashboard,
  plans: `${auth}/plans`,
  adminDashboard: `${admin}/dashboard`,
  dashboardProfile: `${dashboard}/profile`,
  adminCaseTypes: `${admin}/dashboard/caseTypes`,
  adminSpecialization: `${admin}/dashboard/specialization`,
  adminTemplates: `${admin}/dashboard/templates`,
  adminPlans: `${admin}/dashboard/plans`,
  adminSections: `${admin}/dashboard/sections`,
  adminCourts: `${admin}/dashboard/courts`,
  adminCampaigns: `${admin}/dashboard/campaigns`,
  adsRequests: `${admin}/dashboard/ads-requests`,
  dashboardCaseMangement: `${dashboard}/case-management`,
  dashboardCaseDetails: `${dashboard}/case-management/case-details`,
  dashboardCalender: `${dashboard}/calender`,
  dashboardTemplates: `${dashboard}/templates`,
  dashboardAdsCompaigns: `${dashboard}/ads-compaigns`,
  dashboardSettings: `${dashboard}/settings`,
  notFound: '*'
};

export const userDashboardLinks = [
  {
    title: 'Dashboard',
    link: dashboard,
    svg: DashboardLogo,
    activeSvg: ActiveDashboardLogo
  },
  {
    title: 'Profile',
    link: links.dashboardProfile,
    svg: ProfileLogo,
    activeSvg: ProfileActiveLogo
  },
  {
    title: 'Case Management',
    link: links.dashboardCaseMangement,
    svg: CaseManagementLogo,
    activeSvg: CaseManagementActiveLogo
  },
  {
    title: 'Calendar',
    // link: links.dashboardCalender,
    svg: CalenderLogo,
    activeSvg: CalenderActiveLogo
  },
  {
    title: 'Templates',
    // link: links.dashboardTemplates,
    svg: TemplatesLogo,
    activeSvg: TemplateActiveLogo
  },
  {
    title: 'Ads Campaigns',
    // link: links.dashboardAdsCompaigns,
    svg: AddsLogo,
    activeSvg: AddsActiveLogo
  },
  {
    title: 'Settings',
    // link: links.dashboardSettings,
    svg: SettingsLogo,
    activeSvg: SettingsActiveLogo
  }
];

export const adminDashboardLinks = [
  {
    title: 'Dashboard',
    link: links.adminDashboard,
    svg: DashboardLogo,
    activeSvg: ActiveDashboardLogo,
    items: []
  },
  {
    title: 'Case Types',
    link: links.adminCaseTypes,
    svg: CaseTypeLogo,
    activeSvg: ActiveCaseType,
    items: []
  },
  {
    title: 'Specializations',
    link: links.adminSpecialization,
    svg: SpecializationLogo,
    activeSvg: ActiveSpecializationLogo,
    items: []
  },
  {
    title: 'Templates',
    link: links.adminTemplates,
    svg: TemplatesLogo,
    activeSvg: TemplateActiveLogo,
    items: []
  },
  {
    title: 'Settings',
    link: links.adminPlans,
    svg: SettingsLogo,
    activeSvg: SettingsActiveLogo,
    isNested: true,
    items: [
      {
        title: 'Plans',
        link: links.adminPlans,
        svg: PlansLogo,
        activeSvg: ActivePlansLogo
      },
      {
        title: 'Courts',
        link: links.adminCourts,
        svg: DashboardCourtsLogo,
        activeSvg: DashboardActiveCourtsLogo,
        items: []
      },
      {
        title: 'Sections',
        link: links.adminSections,
        svg: PlansLogo,
        activeSvg: ActivePlansLogo
      },
      {
        title: 'Campaigns',
        link: dashboard,
        svg: CompaignsLogo,
        activeSvg: ActiveCompaignsLogo,
        isNested: true,
        items: [
          {
            title: 'All Campaigns',
            link: links.adminCampaigns,
            svg: AddsRequestLogo,
            activeSvg: AddsRequestActiveLogo
          },
          {
            title: 'Ads Requests',
            link: links.adsRequests,
            svg: AddsRequestLogo,
            activeSvg: AddsRequestActiveLogo
          }
        ]
      }
    ]
  }
];

export const menu = [
  {
    icon: '',
    title: 'Home',
    items: []
  },
  {
    icon: '',
    title: 'Education',
    items: [
      {
        title: 'Technical Analysis',
        items: [
          {
            title: 'The Dow Theory',
            to: '/thedowtheory'
          },
          {
            title: 'Charts & Chart Patterns',
            to: '/chart'
          },
          {
            title: 'Trend & Trend Lines',
            to: '/trendlines'
          },
          {
            title: 'Support & Resistance',
            to: '/sandr'
          }
        ]
      },
      {
        title: 'Fundamental Analysis',
        items: [
          {
            title: 'The Dow Theory',
            to: '/thedowtheory'
          },
          {
            title: 'Charts & Chart Patterns',
            to: '/chart'
          },
          {
            title: 'Trend & Trend Lines',
            to: '/trendlines'
          },
          {
            title: 'Support & Resistance',
            to: '/sandr'
          }
        ]
      },
      {
        title: 'Elliot Wave Analysis',
        items: [
          {
            title: 'The Dow Theory',
            to: '/thedowtheory'
          },
          {
            title: 'Charts & Chart Patterns',
            to: '/chart'
          },
          {
            title: 'Trend & Trend Lines',
            to: '/trendlines'
          },
          {
            title: 'Support & Resistance',
            to: '/sandr'
          }
        ]
      }
    ]
  },
  {
    icon: '',
    title: 'Options'
  },
  {
    icon: '',
    title: 'Blog'
  }
];
