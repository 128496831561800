import {
  AppBar,
  Avatar,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Toolbar
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom';

import { links } from 'static/links';
import { useAuthContext } from 'userContext/AuthContext';

const AdminDashboardHeader = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { authUser, clearAuth } = useAuthContext();
  const userData = authUser as DynamicObject;
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = (type: string) => {
    if (type === 'Logout') {
      clearAuth();
      setAnchorElUser(null);
      navigate(links.landing);
      return;
    }
    setAnchorElUser(null);
  };
  return (
    <AppBar
      position='static'
      sx={{
        height: '60px'
      }}
    >
      <Toolbar
        variant='regular'
        className={classes.AdminDashboardHeaderContainer}
        disableGutters
      >
        <Grid
          item
          xs={12}
          justifyContent='end'
          display='flex'
          alignItems='center'
        >
          <Box
            display='flex'
            alignItems='center'
            sx={{
              cursor: 'pointer'
            }}
            gap={1}
          >
            <Box sx={{ flexGrow: 0 }}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={userData?.user?.first_name?.toUpperCase()}
                  src='/static/images/avatar/2.jpg'
                />
                <ArrowDropDownIcon
                  style={{ fill: 'black', marginLeft: '10px' }}
                />
              </IconButton>
              <Menu
                sx={{ mt: '45px' }}
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                PaperProps={{
                  style: {
                    width: '165px'
                  }
                }}
              >
                {settings.map(setting => (
                  <MenuItem
                    key={setting}
                    onClick={() => handleCloseUserMenu(setting)}
                  >
                    {setting}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default memo(AdminDashboardHeader);

const settings = ['Logout'];

const useStyles = makeStyles((theme: Theme) => ({
  AdminDashboardHeaderContainer: {
    backgroundColor: '#FFFFFF',
    padding: '10px 80px',
    display: 'flex',
    justifyContent: 'flex-end ',
    minHeight: '100% !important'
  },
  DashboardHeaderLogo: {
    height: '52px',
    width: '53px',
    marginRight: '18px'
  }
}));
