import { AppBar, Box, Grid, Theme, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import logo from 'static/images/WakeelLogo.png';
import { links } from 'static/links';

const AuthHeader = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate(links.landing);
  };
  return (
    <AppBar
      position='static'
      sx={{
        height: '64px'
      }}
    >
      <Toolbar
        variant='regular'
        className={classes.AuthHeaderContainer}
        disableGutters
      >
        <Grid
          item
          xs={12}
          justifyContent='start'
          display='flex'
          alignItems='center'
        >
          <Box
            display='flex'
            alignItems='center'
            onClick={handleLogoClick}
            sx={{
              cursor: 'pointer'
            }}
          >
            <img src={logo} alt='logo' className={classes.AuthHeaderLogo} />
            <Typography variant='body1'>WAKEEL</Typography>
          </Box>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default memo(AuthHeader);

const useStyles = makeStyles((theme: Theme) => ({
  AuthHeaderContainer: {
    backgroundColor: '#FFFFFF',
    padding: '10px 80px'
  },
  AuthHeaderLogo: {
    height: '52px',
    width: '53px',
    marginRight: '18px'
  }
}));
