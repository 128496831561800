import { Box, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import logo from "static/images/WakeelLogo.png";
import { userDashboardLinks } from "static/links";

const DashboardSideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();

  const handleNavigation = (link: string) => {
    if (!link) {
      return;
    }
    navigate(link);
  };

  return (
    <Box className={classes.SiderBarContainer}>
      <Stack
        direction="column"
        paddingTop="19px"
        spacing={6}
        width="60%"
        margin="0 auto"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            cursor: "pointer",
          }}
        >
          <img src={logo} alt="logo" className={classes.AuthHeaderLogo} />
          <Typography className={classes.HeaderLogoText}>WAKEEL</Typography>
        </Box>
        {userDashboardLinks.map((el) => (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              gap={"20px"}
              sx={{
                cursor: "pointer",
              }}
              onClick={() => handleNavigation(el.link ? el.link : "")}
            >
              <img
                src={location.pathname === el.link ? el.activeSvg : el.svg}
                alt={el.title}
              />
              <Typography
                className={
                  location.pathname === el.link
                    ? classes.ActiveSideBarLinks
                    : classes.SideBarLinks
                }
              >
                {el.title}
              </Typography>
            </Box>
          </>
        ))}
      </Stack>
    </Box>
  );
};

export default memo(DashboardSideBar);

const useStyles = makeStyles({
  SiderBarContainer: {
    overflow: "hidden",
    backgroundColor: "#383838",
    minHeight: "100vh",
    height: "100%",
  },
  AuthHeaderLogo: {
    height: "52px",
    width: "53px",
    marginRight: "18px",
  },
  HeaderLogoText: {
    fontFamily: "Manrope !important",
    fontWeight: "700 !important",
    fontSize: "20px !important",
    color: "#FFFFFF !important",
  },
  SideBarLinks: {
    fontFamily: "Poppins !important",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    color: "#FFFFFF !important",
    opacity: 0.6,
  },
  ActiveSideBarLinks: {
    fontFamily: "Poppins !important",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    color: "#FFFFFF !important",
  },
});
