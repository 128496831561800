/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@tanstack/react-query';

import { getApi } from 'services/api';

export const useGetPaginatedData = (
  name: string,
  endPoint: string,
  refetchOptions: Record<string, any>,
  options?: Record<string, any>
) => {
  const query: unknown = useQuery(
    [name, { ...refetchOptions }],
    () => getApi(endPoint),
    {
      keepPreviousData: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      retry: 1,
      ...options
    }
  );
  return query as Query;
};
interface Query {
  data: Record<string, any>;
  error: string;
  isFetch: boolean;
  isFetching: boolean;
  isLoading: boolean;
  isPreviousData: boolean;
  refetch: () => void;
}
