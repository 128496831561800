import { memo, Suspense } from 'react';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { AdminLayoutProps } from 'interfaces';
import AdminDashboardHeader from 'components/shared/AdminDashboardHeader';
import AdminDashboardSidebar from 'components/shared/AdminDashboardSidebar';

const AdminLayout = ({ children }: AdminLayoutProps) => {
  const classes = useStyles();

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Box component='main' className={classes.MainContainer}>
          <Grid container>
            <Grid item xs={3} lg={2} xl={2}>
              <AdminDashboardSidebar />
            </Grid>
            <Grid item xs={9} lg={10} xl={10}>
              <AdminDashboardHeader />
              <Suspense fallback={<div />}>{children}</Suspense>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default memo(AdminLayout);

const useStyles = makeStyles({
  MainContainer: {
    overflow: 'hidden',
    backgroundColor: '#f5f5f5',
    flexGrow: 1,
    minHeight: '100vh'
  }
});
