export const COLOR_BLACK = { color: "#232426 !important" };
export const COLOR_LIGHT_BLACK = { color: "#383838 !important" };
export const THEME_GREY_COLOR = { color: "#B6B6B6 !important" };
export const THEME_WHITE_COLOR = { color: "#FFFFFF !important" };
export const DARK_BLUISH_GREY_COLOR = { color: "#1D2130 !important" };
export const THEME_BACKGROUND_WHITE_COLOR = {
  background: "#FFFFFF !important",
};
export const BACKGROUND_LIGHT_BLACK = { background: "#383838 !important" };
export const FONT_32 = { fontSize: "32px !important" };
export const FONT_30 = { fontSize: "30px !important" };
export const FONT_14 = { fontSize: "14px !important" };
export const FONT_16 = { fontSize: "16px !important" };
export const FONT_18 = { fontSize: "18px !important" };
export const FONT_25 = { fontSize: "25px !important" };

export const POPPINS_FONT = { fontFamily: "Poppins !important" };
export const NOTO_SANS_THAI = { fontFamily: "Noto Sans Thai !important" };
export const INTER_FONT = { fontFamily: "Inter !important" };
export const ROBOTTO_FONT = { fontFamily: "Roboto !important" };
export const FONT_WEIGHT_500 = { fontWeight: "500 !important" };
export const FONT_WEIGHT_400 = { fontWeight: "400 !important" };

export const WHITE_COLOR = "#FFFFFF";
