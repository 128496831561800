import axios from "axios";
import sign from "jwt-encode";
import { mapAPIError } from "./mapAPIError";

const BASE_URL = process.env.REACT_APP_BASE_URL_dev;

export const api = async ({
  data,
  endPoint,
  id,
  method = "POST",
  params,
}: APIParams) => {
  const url = `${BASE_URL}${endPoint}${id ? `/${id}` : ""}${params ?? ""}`;
  const token = localStorage.getItem("user")?.length
    ? `Bearer ${JSON.parse(localStorage.getItem("user") ?? "{}")?.token}`
    : "";

  try {
    return await axios({
      data,
      headers: {
        Authorization: token,
      },
      method,
      url,
    });
  } catch (err) {
    throw mapAPIError(err);
  }
};

export const getApi = async (endPoint: string) => {
  const token = localStorage.getItem("user")?.length
    ? `Bearer ${JSON.parse(localStorage.getItem("user") ?? "{}")?.token}`
    : "";

  try {
    return await axios({
      headers: {
        Authorization: token,
      },
      method: "get",
      url: `${BASE_URL}${endPoint}`,
    });
  } catch (err) {
    throw mapAPIError(err);
  }
};

export const getJWTApi = async (endPoint: string) => {
  const currentTime = new Date();
  const payload = {
    company: process.env.REACT_APP_OPEN_API_COMPANY,
    expires_at: new Date(currentTime.getTime() + 10 * 60 * 1000),
  };
  const secret: string = process.env.REACT_APP_OPEN_API_SECRET ?? "";

  const token = sign(payload, secret, { alg: "HS256" });
  try {
    return await axios({
      method: "post",
      url: `${BASE_URL}${endPoint}`,
      data: {
        auth_key: token,
      },
    });
  } catch (err) {
    throw mapAPIError(err);
  }
};

interface APIParams {
  endPoint: string;
  data?: Record<string, unknown>;
  id?: string | number;
  params?: string;
  method?: "DELETE" | "PATCH" | "POST" | "PUT";
}
