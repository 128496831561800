export const TableExportIcon = (
  <svg
    width='24'
    height='32'
    viewBox='0 0 24 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M7 17.5L12 22.5L17 17.5H7Z' fill='black' fill-opacity='0.54' />
    <path
      d='M17 14.5L12 9.5L7 14.5L17 14.5Z'
      fill='black'
      fill-opacity='0.54'
    />
  </svg>
);
