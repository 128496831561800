export const DeleteLawyerIcon = (
  row: DynamicObject,
  handleAction: (row: DynamicObject, action: string) => void
) => (
  <svg
    onClick={() => handleAction(row, 'delete')}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7 21C6.45 21 5.97933 20.8043 5.588 20.413C5.196 20.021 5 19.55 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.8043 20.021 18.413 20.413C18.021 20.8043 17.55 21 17 21H7ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z'
      fill='#FF4343'
    />
  </svg>
);
