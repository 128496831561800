import * as yup from 'yup';

export const addCaseeType = yup.object().shape({
  name: yup.string().required('Category Name is required')
});

export const TemplateSchema = yup.object().shape({
  title: yup.string().required().label('Title'),
  is_paid: yup.string().required().label('is_paid'),
  amount: yup.number().when('is_paid', (isPaid: unknown) => {
    if (isPaid === '1')
      return yup
        .number()
        .required()
        .min(1)
        .typeError('Amount must be a number');
    return yup.number().nullable();
  })
});

export const SpecializationSchema = yup.object({
  name: yup.string().required().label('Name')
});

export const PlanSchema = yup.object({
  duration: yup.string().required().label('Plan Name '),
  fee: yup
    .number()
    .required('price is required')
    .min(1)
    .typeError('Price is required and must be a number')
    .label('Price')
});
export const CourtSchema = yup.object({
  name: yup.string().required().label('Name')
});
export const CampaignSchema = yup.object({
  title: yup.string().required().label('Title'),
  recursion_type: yup.string().required().label('is_paid'),
  rate: yup
    .number()
    .required()
    .typeError('Amount must be a number')
    .label('Price')
});
export const sectionSchema = yup.object({
  name: yup.string().required().label('Section Name'),
  description: yup.string().required().label('Section Description')
});
