import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SubmitHandler, useForm } from 'react-hook-form';
import { makeStyles } from '@mui/styles';

import AppDialog from 'components/Common/AppDialog';
import { COLOR_BLACK, FONT_14, POPPINS_FONT, ROBOTTO_FONT } from 'utils/static';
import AppInputField from 'components/Common/AppInputField';
import { EN_LABELS } from 'static/labels';
import { useAddSection, useUpdateSection } from 'hooks/AdminDashboard/Sections';
import { SectionType } from '.';
import { useDisplayError } from 'hooks/useDisplayError';
import { yupResolver } from '@hookform/resolvers/yup';
import { sectionSchema } from 'validations/Dashboard/AdminProfile';

const AddSection = ({
  open,
  handleClose,
  selectedSection
}: AddSectionProps) => {
  const classes = useStyles();
  const displayError = useDisplayError();
  const handleSectionSuccess = () => {
    handleClose();
  };

  const addSection = useAddSection({ onSuccess: handleSectionSuccess });

  const updateSection = useUpdateSection({ onSuccess: handleSectionSuccess });

  const error = addSection;
  const isLoading = addSection.isLoading || updateSection.isLoading;

  if (error && error?.error?.message && !error?.error?.data) {
    displayError(error?.error?.message);
  } else if (error && error?.error?.data) {
    displayError(error?.error?.data?.fee);
  }
  const updateError = updateSection;
  if (updateError && updateError?.error?.message && !updateError?.error?.data) {
    displayError(updateError?.error?.message);
  } else if (updateError?.error?.data) {
    displayError(updateError?.error?.data?.fee);
  }

  const { control, handleSubmit } = useForm<AddSectionFormInputs>({
    defaultValues: {
      name: selectedSection ? selectedSection.name : '',
      description: selectedSection ? selectedSection.description : ''
    },
    resolver: yupResolver(sectionSchema)
  });
  const handleAddSpec: SubmitHandler<AddSectionFormInputs> = data => {
    if (selectedSection) {
      updateSection.mutate({ data, id: selectedSection.id });
    } else {
      addSection.mutate({ data });
    }
  };

  return (
    <AppDialog
      open={open}
      handleClose={() => !isLoading && handleClose()}
      title={
        <Typography color='white' fontSize='16px' letterSpacing='0.2px'>
          {selectedSection ? 'Edit Section' : 'Add New Section'}
        </Typography>
      }
      maxWidth='md'
      headerBackground='#383838'
      disabledPadding
      customBottons={
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
      }
      footer={
        <Stack
          direction='row'
          justifyContent='flex-end'
          spacing={2}
          padding='25px'
        >
          <Button
            variant='outlined'
            className={classes.CancelBtn}
            onClick={handleClose}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            className={classes.AddBtn}
            onClick={handleSubmit(handleAddSpec)}
            disabled={isLoading}
          >
            {selectedSection ? 'Update Section' : 'Save '}
          </Button>
        </Stack>
      }
    >
      <Box component='form'>
        <Grid container spacing={4} padding='25px' pb={0}>
          <AppInputField
            inputFieldClass={classes.AddCaseField}
            grid={12}
            control={control}
            name='name'
            required
            inputTopLabel={EN_LABELS.sectionName}
            inputLabelClass={classes.AddCaseFieldLabel}
            type='text'
            variant='outlined'
            inputProps={{ color: '#B6B6B6', fontSize: '14px' }}
            placeholder={EN_LABELS.sectionName}
          />
          <AppInputField
            inputFieldClass={classes.AddCaseField}
            grid={12}
            control={control}
            name='description'
            multiline
            required
            inputTopLabel={EN_LABELS.sectionDescription}
            inputLabelClass={classes.AddCaseFieldLabel}
            type='text'
            variant='outlined'
            inputProps={{ color: '#B6B6B6', fontSize: '14px' }}
            placeholder={EN_LABELS.sectionDescription}
          />
        </Grid>
      </Box>
    </AppDialog>
  );
};

export default AddSection;

const useStyles = makeStyles({
  AddCaseField: {
    border: '1px solid #E9E9E9',
    boxShadow:
      '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)',
    borderRadius: '5px',
    background: '#FFFFFF',
    '& input': {
      padding: '10px',
      fontSize: '12px'
    },
    '& textarea': {
      height: '154px !important',
      fontSize: '12px'
    }
  },
  AddCaseFieldLabel: {
    ...COLOR_BLACK,
    ...FONT_14,
    ...POPPINS_FONT,
    fontWeight: '400 !important',
    marginBottom: '15px !important'
  },
  CancelBtn: {
    color: '#52575C !important',
    ...FONT_14,
    ...ROBOTTO_FONT
  },
  AddBtn: {
    background: '#02B22B !important',
    color: '#FFFFFF !important',
    ...FONT_14,
    ...ROBOTTO_FONT
  },
  closeIcon: {
    fill: 'white !important'
  }
});

interface AddSectionProps {
  open: boolean;
  handleClose: () => void;
  selectedSection: SectionType | null;
}

interface AddSectionFormInputs {
  name: string;
  description: string;
  id: number;
}
