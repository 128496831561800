import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { memo, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import logo from "static/images/WakeelAdminLogo.png";
import { adminDashboardLinks, links } from "static/links";

export function hasChildren(item: DynamicObject) {
  const { items: children } = item;

  const childs =
    children === undefined
      ? false
      : children.constructor !== Array
      ? false
      : children.length === 0
      ? false
      : true;

  return childs;
}

const AdminDashboardSidebar = () => {
  const location = useLocation();
  const classes = useStyles();

  const SingleLevel = ({ item, onClick }: DynamicObject) => {
    return (
      <ListItem
        button
        className={classes.disablePaddingRight}
        onClick={onClick}
      >
        <ListItemIcon className={classes.customMinWidth}>
          {" "}
          <img
            src={location.pathname === item.link ? item.activeSvg : item.svg}
            alt={item.link}
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography
              className={
                location.pathname === item.link
                  ? classes.ActiveSideBarLinks
                  : classes.SideBarLinks
              }
            >
              {item.title}
            </Typography>
          }
        />
      </ListItem>
    );
  };

  const MultiLevel = ({ item, onClick }: DynamicObject) => {
    const { items: children } = item;
    const [open, setOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
      if (
        location.pathname === links.adminPlans ||
        location.pathname === links.adminCourts ||
        location.pathname === links.adminCampaigns
      ) {
        setOpen(true);
      }
    }, [location.pathname]);

    const handleClick = () => {
      setOpen((prev) => !prev);
    };

    return (
      <React.Fragment>
        <ListItem
          button
          onClick={handleClick}
          className={classes.disablePaddingRight}
        >
          <ListItemIcon className={classes.customMinWidth}>
            <img
              src={location.pathname === item.link ? item.activeSvg : item.svg}
              alt={item.title}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                className={
                  location.pathname === item.link
                    ? classes.ActiveSideBarLinks
                    : classes.SideBarLinks
                }
              >
                {item.title}
              </Typography>
            }
          />
          {open ? (
            <ExpandLess className={classes.expandIcon} />
          ) : (
            <ExpandMore className={classes.expandIcon} />
          )}
        </ListItem>
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          className={`${classes.disableMarginTop} ${classes.marginLeft10px}`}
        >
          <List
            component="div"
            disablePadding
            className={classes.marginLeft7px}
          >
            {children.map((child: DynamicObject, key: number) => (
              <MenuItem
                key={key}
                item={child}
                onClick={() => {
                  navigate(child.link);
                }}
              />
            ))}
          </List>
        </Collapse>
      </React.Fragment>
    );
  };

  const MenuItem = ({ item, onClick }: DynamicObject) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel;
    return <Component item={item} onClick={onClick} />;
  };
  const navigate = useNavigate();

  return (
    <Box className={classes.SiderBarContainer}>
      <Stack
        direction="column"
        paddingTop="19px"
        spacing={4}
        width="90%"
        margin="0 auto"
      >
        <Box className={classes.AuthHeaderLogoContainer}>
          <img src={logo} alt="logo" className={classes.AuthHeaderLogo} />
          <Typography className={classes.HeaderLogoText}>
            Individual lawyer
          </Typography>
        </Box>
        {adminDashboardLinks.map((item, key) => (
          <MenuItem
            key={key}
            item={item}
            onClick={() => {
              navigate(item.link);
            }}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default memo(AdminDashboardSidebar);

const useStyles = makeStyles((theme: Theme) => ({
  SiderBarContainer: {
    overflow: "hidden",
    backgroundColor: "#383838",
    minHeight: "100vh",
    height: "100%",
  },
  AuthHeaderLogo: {
    height: "49px",
    width: "50px",
    marginRight: "18px",
  },
  AuthHeaderLogoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "start",
      paddingLeft: "10px",
    },
  },
  HeaderLogoText: {
    fontFamily: "Manrope !important",
    fontWeight: "700 !important",
    fontSize: "16px !important",
    color: "#FFFFFF !important",
  },
  SideBarLinks: {
    fontFamily: "Poppins !important",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    color: "#FFFFFF !important",
    opacity: 0.6,
  },
  ActiveSideBarLinks: {
    fontFamily: "Poppins !important",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    color: "#FFFFFF !important",
    [theme.breakpoints.down("lg")]: {
      // fontSize: '12px !important'
    },
  },
  disablePaddingRight: {
    paddingRight: "0px !important",
  },
  disableMarginTop: {
    marginTop: "0px !important",
  },
  marginLeft7px: {
    marginLeft: "7px",
  },
  expandIcon: {
    fill: "#FFFFFF !important",
  },
  marginLeft10px: {
    marginLeft: "10px",
  },
  customMinWidth: {
    minWidth: "40px !important",
  },
}));
