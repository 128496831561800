import { memo, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import PublicLayout from 'components/Layout/PublicLayout';

const PublicOutlet = () => {
  return (
    <Suspense fallback='loading....'>
      <PublicLayout>
        <Outlet />
      </PublicLayout>
    </Suspense>
  );
};
export default memo(PublicOutlet);
