export enum Apis {
  register = '/signup',
  createPassword = '/createpassword',
  forgotPassword = '/forgotpassword',
  resetPassword = '/resetpassword',
  login = '/login',
  updateUserProfile = '/user/updatepofile',
  getUserById = '/user',
  getUserprofileById = '/getuserprofile',
  getLawyers = '/lawyers',
  getLawyersStats = '/dashboardstats',
  changeUserStatus = `/user/updatestatus`,
  deleteUser = '/user',
  getUsers = '/users',
  getCaseTypes = '/casetypes',
  deleteCaseType = '/casetype',
  addCase = '/casetype',
  updateCase = '/casetype',
  getSpecialization = '/specializations',
  deleteSpecialization = '/specialization',
  addSpecialization = '/specialization',
  getPlans = '/plans',
  addPlan = '/plan',
  deletePlan = '/plan',
  updatePlan = '/plan',
  getCourts = '/courts',
  addCourt = '/court',
  deleteCourt = '/court',
  getTeamplates = '/templates',
  addTemplate = '/template',
  getCampaigns = '/useradcampaigns',
  addCampaign = '/useradcampaign',
  getWorkExperience = '/workexperiences',
  getUserWorkExperience = '/getuserworkexperiences',
  addWorkExperience = '/workexperience',
  getUserCases = '/usercases',
  addUserCase = '/usercase',
  getUserSpecialization = '/users/getspecializations',
  addUserSpecialization = '/users/specializations',
  getUserEducation = '/educations',
  getUserEducationById = '/getusereducations',
  addUserEducation = '/education',
  getSearchLawyers = '/search',
  useGetRegistrationCourts = '/getcourts',
  useGetClients = '/clients',
  addClient = '/client',
  deleteClient = '/client',
  useGetClientCases = '/cases',
  useAddClientCase = '/case',
  usegetReminder = '/reminders',
  useAddCaseReminder = '/reminder',
  useGetNotes = '/notes',
  useAddNote = '/note',
  section = '/section',
  sections = '/sections'
}

export enum ApiMethods {
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE'
}
