export const EditIcon = (
  row: DynamicObject,
  handleAction: (row: DynamicObject, action: string) => void
) => (
  <svg
    onClick={() => handleAction(row, "edit")}
    width="22"
    height="22"
    style={{ marginRight: "10px" }}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.58333 21.7252C4.07917 21.7252 3.64742 21.5456 3.28808 21.1862C2.92936 20.8275 2.75 20.3961 2.75 19.8919V7.05856C2.75 6.55439 2.92936 6.12264 3.28808 5.76331C3.64742 5.40459 4.07917 5.22523 4.58333 5.22523H12.7646L10.9312 7.05856H4.58333V19.8919H17.4167V13.5211L19.25 11.6877V19.8919C19.25 20.3961 19.0706 20.8275 18.7119 21.1862C18.3526 21.5456 17.9208 21.7252 17.4167 21.7252H4.58333ZM14.8271 5.75231L16.1333 7.03564L10.0833 13.0856V14.3919H11.3667L17.4396 8.31898L18.7458 9.60231L12.1458 16.2252H8.25V12.3294L14.8271 5.75231ZM18.7458 9.60231L14.8271 5.75231L17.1187 3.46064C17.4854 3.09398 17.9248 2.91064 18.4369 2.91064C18.9484 2.91064 19.3799 3.09398 19.7312 3.46064L21.0146 4.76689C21.366 5.11828 21.5417 5.54606 21.5417 6.05023C21.5417 6.55439 21.366 6.98217 21.0146 7.33356L18.7458 9.60231Z"
      fill="#383838"
    />
  </svg>
);
