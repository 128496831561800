export const NoDataSvg = () => (
  <svg
    width="343"
    height="335"
    viewBox="0 0 343 335"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_992_1546)">
      <path
        d="M217.75 75.237H125.326C123.22 75.2396 121.201 76.0767 119.712 77.5648C118.222 79.0529 117.385 81.0704 117.382 83.1749V288.421L116.323 288.744L93.6498 295.682C92.5753 296.009 91.4147 295.897 90.4227 295.37C89.4308 294.844 88.6886 293.945 88.3591 292.872L20.9173 72.7551C20.5891 71.6814 20.701 70.5215 21.2283 69.5302C21.7556 68.5388 22.6553 67.7973 23.7296 67.4685L58.6686 56.7788L159.958 25.8L194.897 15.1104C195.429 14.9469 195.987 14.8897 196.541 14.9422C197.095 14.9947 197.633 15.1559 198.125 15.4164C198.616 15.6769 199.051 16.0317 199.405 16.4604C199.759 16.8892 200.025 17.3835 200.188 17.9151L217.427 74.1786L217.75 75.237Z"
        fill="#FFCA02"
        fill-opacity="0.2"
      />
      <path
        d="M237.919 74.1786L217.142 6.36815C216.797 5.2383 216.232 4.18759 215.479 3.27604C214.727 2.3645 213.802 1.61 212.758 1.05565C211.714 0.5013 210.571 0.157964 209.394 0.0452731C208.217 -0.0674173 207.029 0.0527462 205.899 0.398885L156.776 15.4226L55.4924 46.4067L6.37025 61.4357C4.0883 62.1359 2.17759 63.7121 1.05752 65.8184C-0.0625484 67.9247 -0.300467 70.389 0.395999 72.6704L71.4073 304.419C71.973 306.26 73.1146 307.872 74.6645 309.018C76.2144 310.164 78.091 310.783 80.019 310.785C80.9113 310.785 81.7986 310.652 82.651 310.388L116.324 300.09L117.383 299.762V298.656L116.324 298.979L82.3387 309.377C80.3244 309.991 78.1489 309.781 76.2892 308.794C74.4296 307.807 73.0377 306.123 72.4189 304.112L1.41308 72.3582C1.10648 71.3617 0.999623 70.3146 1.09862 69.2767C1.19762 68.2389 1.50053 67.2308 1.99001 66.3101C2.47949 65.3894 3.14594 64.5742 3.95117 63.9112C4.7564 63.2483 5.6846 62.7505 6.68263 62.4465L55.8047 47.4175L157.089 16.4387L206.211 1.40965C206.968 1.17882 207.755 1.06112 208.547 1.06038C210.245 1.06419 211.898 1.61135 213.263 2.62172C214.627 3.63208 215.633 5.05256 216.131 6.67509L236.812 74.1786L237.141 75.237H238.242L237.919 74.1786Z"
        fill="#3F3D56"
      />
      <path
        d="M64.9742 67.6416C63.9534 67.6408 62.9597 67.3133 62.1389 66.7069C61.3181 66.1005 60.7134 65.2473 60.4134 64.2724L53.5918 42.0084C53.4084 41.4103 53.3448 40.7821 53.4046 40.1595C53.4643 39.5368 53.6462 38.9321 53.9398 38.3797C54.2335 37.8274 54.6332 37.3382 55.1161 36.9402C55.599 36.5422 56.1556 36.2432 56.7542 36.0602L149.935 7.55597C151.144 7.1874 152.449 7.3131 153.565 7.90548C154.682 8.49785 155.517 9.5085 155.888 10.7156L162.71 32.9798C163.078 34.1877 162.952 35.4923 162.36 36.6074C161.767 37.7226 160.755 38.5573 159.548 38.9283L66.3667 67.4325C65.9155 67.5709 65.4462 67.6413 64.9742 67.6416Z"
        fill="#FFCA02"
      />
      <path
        d="M100.708 23.7896C106.558 23.7896 111.3 19.051 111.3 13.2058C111.3 7.36048 106.558 2.62195 100.708 2.62195C94.8576 2.62195 90.1152 7.36048 90.1152 13.2058C90.1152 19.051 94.8576 23.7896 100.708 23.7896Z"
        fill="#FFCA02"
      />
      <path
        d="M100.709 19.9078C104.414 19.9078 107.417 16.9072 107.417 13.2058C107.417 9.50437 104.414 6.50378 100.709 6.50378C97.005 6.50378 94.002 9.50437 94.002 13.2058C94.002 16.9072 97.005 19.9078 100.709 19.9078Z"
        fill="white"
      />
      <path
        d="M319.167 308.081H140.156C138.963 308.079 137.818 307.605 136.974 306.762C136.13 305.919 135.656 304.775 135.654 303.583V89.2606C135.656 88.068 136.13 86.9247 136.974 86.0814C137.818 85.2381 138.963 84.7638 140.156 84.7625H319.167C320.361 84.7638 321.505 85.2382 322.349 86.0814C323.193 86.9247 323.667 88.068 323.669 89.2606V303.583C323.667 304.775 323.193 305.919 322.349 306.762C321.505 307.605 320.361 308.079 319.167 308.081Z"
        fill="#FFCA02"
        fill-opacity="0.2"
      />
      <path
        d="M236.81 74.1786H125.326C122.939 74.182 120.651 75.1309 118.963 76.8173C117.275 78.5036 116.326 80.7899 116.322 83.1748V298.979L117.382 298.656V83.1748C117.384 81.0704 118.222 79.0528 119.711 77.5647C121.2 76.0767 123.22 75.2395 125.326 75.237H237.139L236.81 74.1786ZM333.995 74.1786H125.326C122.939 74.182 120.651 75.1309 118.963 76.8173C117.275 78.5036 116.326 80.7899 116.322 83.1748V325.544C116.326 327.929 117.275 330.215 118.963 331.902C120.651 333.588 122.939 334.537 125.326 334.54H333.995C336.382 334.537 338.67 333.588 340.358 331.902C342.046 330.215 342.995 327.929 342.999 325.544V83.1748C342.995 80.7899 342.046 78.5036 340.358 76.8173C338.67 75.1309 336.382 74.182 333.995 74.1786ZM341.94 325.544C341.937 327.648 341.099 329.666 339.61 331.154C338.121 332.642 336.102 333.479 333.995 333.482H125.326C123.22 333.479 121.2 332.642 119.711 331.154C118.222 329.666 117.384 327.648 117.382 325.544V83.1748C117.384 81.0704 118.222 79.0528 119.711 77.5647C121.2 76.0767 123.22 75.2395 125.326 75.237H333.995C336.102 75.2395 338.121 76.0767 339.61 77.5647C341.099 79.0528 341.937 81.0704 341.94 83.1748V325.544Z"
        fill="#3F3D56"
      />
      <path
        d="M278.388 97.463H180.938C179.675 97.4616 178.463 96.9593 177.57 96.0665C176.676 95.1736 176.173 93.963 176.172 92.7003V69.4159C176.173 68.1532 176.676 66.9426 177.57 66.0497C178.463 65.1569 179.675 64.6546 180.938 64.6532H278.388C279.652 64.6546 280.863 65.1569 281.757 66.0497C282.651 66.9426 283.153 68.1532 283.155 69.4159V92.7003C283.153 93.963 282.651 95.1736 281.757 96.0665C280.864 96.9593 279.652 97.4616 278.388 97.463Z"
        fill="#FFCA02"
      />
      <path
        d="M229.663 66.2409C235.513 66.2409 240.255 61.5023 240.255 55.6571C240.255 49.8118 235.513 45.0732 229.663 45.0732C223.813 45.0732 219.07 49.8118 219.07 55.6571C219.07 61.5023 223.813 66.2409 229.663 66.2409Z"
        fill="#FFCA02"
      />
      <path
        d="M229.663 62.1035C233.226 62.1035 236.114 59.2173 236.114 55.657C236.114 52.0967 233.226 49.2104 229.663 49.2104C226.099 49.2104 223.211 52.0967 223.211 55.657C223.211 59.2173 226.099 62.1035 229.663 62.1035Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_992_1546">
        <rect width="343" height="334.54" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
