/* eslint-disable import/no-anonymous-default-export */
const fullName = 'Full Name';
const cnic = 'CNIC';
const cnicPlaceHolder = '3520288178266';
const fullNamePlaceHolder = 'Write your name here';
const emailPlaceHolder = 'Write your email here';
const phoneNumber = 'Phone Number';
const phoneNumberPlaceHolder = 'Enter phone number';
const city = 'City';
const cityPlaceHolder = 'Select City';
const lisence = 'Bar License/Reg. License';
const email = 'Email Address';
const password = 'Password';
const newPassword = 'Create New Password';
const newPasswordPLaceHolder = 'Enter Password';
const confirmPassword = 'Confirm Password';
const confirmPasswordPlaceHolder = 'Enter Password';
const gender = 'Gender';
const genderPlaceHolder = 'Select Gender';
const firstName = 'First Name';
const firstNamePlaceHolder = 'Enter First Name';
const lastName = 'Last Name';
const lastNamePlaceHolder = 'Enter Last Name';
const dob = 'Date of Birth';
const address1 = 'Address 1';
const address2 = 'Address 2';
const addressPlaceHolder = 'Enter your address';
const state = 'State';
const statePlaceHolder = 'Select State';
const about = 'About';
const aboutPlaceHolder = 'Tell briefly about yourself...';
const title = 'Title';
const titlePlaceHolder = 'Enter Title';
const from = 'From';
const fromPlaceHolder = 'Enter From';
const to = 'To';
const companyName = 'Company Name';
const companyPlaceholder = 'Enter Company Name';
const categoryName = 'Category Name';
const categoryNamePlaceHolder = 'Add category name';
const selectCategoryIcon = 'Select Category Icon';
const specializationName = 'Specialization Name';
const specializationNamePlaceHolder = 'Add specialization name';
const templateName = 'Set Template Name';
const templateDescription = 'Set Template Description';
const templateDescriptionPlaceHolder = 'Set Template Description';
const templateNamePlaceHolder = 'Set Template name';
const templateType = 'Select Template Type';
const templatePrice = 'Set Template Price';
const templateUpload = 'Upload Template';
const planName = 'Plan Name';
const planPrice = 'Set Plan Price';
const courtName = 'Court Name';
const courtNamePlaceHolder = 'Add court name';
const campaignName = 'Ads Campaign Name';
const campaignNamePlaceHolder = 'Ads campaign name';
const campaignPrice = 'Ads Campaign Price';
const campaignPricePlaceHolder = 'Ads Campaign Price';
const campaignIcon = 'Select Campaign Icon';
const campaignType = 'Set Campaign Type';
const approvalReason = 'Reason for Approval';
const approvalReasonPlaceHolder = 'Enter Reason for Approval';
const rejectReason = 'Reason For Rejection';
const rejectReasonPlaceHolder = 'Enter Reason for Rejection ';
const district = 'District';
const documentTitle = 'Document Title';
const uploadDocument = 'Upload Document';
const degree = 'Degree/Program Title';
const degreePlaceHolder = 'Enter Degree/Program Title';
const institute = 'Institute Name';
const institutePlaceHolder = 'Enter Institute Name';
const client = 'Client Name';
const clientPlaceholder = 'Jhon doe';
const tagLine = 'Tag Line';
const tagLinePlaceholder = 'Write tagline';
const caseTitle = 'Case Title';
const caseTitlePlaceHolder = 'Enter Case Title';
const caseCategory = 'Case Category';
const description = 'Description';
const descriptionPlaceHolder = 'Write brief description';
const reminderTitlePlaceHolder = 'Enter the title of reminder';
const selectDate = 'Select Date';
const selectDatePlaceHolder = '12/27/2023';
const clientCnic = 'Client CNIC';
const clientCnicPlaceHolder = 'Enter CNIC';
const caseNo = 'Case No';
const caseNoPlaceHolder = 'Add case number';
const selectTime = 'Select Time';
const selectTimePlaceholder = '07:00 PM';
const courtPlaceHolder = 'Court Name';
const noteTitlePlaceHolder = 'Enter the title of note';
const sectionName = 'Section name';
const sectionDescription = 'Section description';
export default {
  fullName,
  fullNamePlaceHolder,
  emailPlaceHolder,
  email,
  cnic,
  cnicPlaceHolder,
  phoneNumber,
  phoneNumberPlaceHolder,
  city,
  cityPlaceHolder,
  lisence,
  newPassword,
  newPasswordPLaceHolder,
  confirmPassword,
  confirmPasswordPlaceHolder,
  password,
  gender,
  genderPlaceHolder,
  firstName,
  firstNamePlaceHolder,
  lastName,
  lastNamePlaceHolder,
  dob,
  address1,
  address2,
  addressPlaceHolder,
  state,
  statePlaceHolder,
  about,
  aboutPlaceHolder,
  title,
  titlePlaceHolder,
  from,
  fromPlaceHolder,
  to,
  companyName,
  companyPlaceholder,
  categoryName,
  categoryNamePlaceHolder,
  selectCategoryIcon,
  specializationName,
  specializationNamePlaceHolder,
  templateName,
  templateDescription,
  templateDescriptionPlaceHolder,
  templateNamePlaceHolder,
  templateType,
  templatePrice,
  templateUpload,
  planName,
  planPrice,
  courtName,
  courtNamePlaceHolder,
  campaignName,
  campaignNamePlaceHolder,
  campaignPrice,
  campaignPricePlaceHolder,
  campaignIcon,
  campaignType,
  approvalReason,
  approvalReasonPlaceHolder,
  rejectReason,
  rejectReasonPlaceHolder,
  district,
  documentTitle,
  uploadDocument,
  degree,
  degreePlaceHolder,
  institute,
  institutePlaceHolder,
  client,
  clientPlaceholder,
  tagLine,
  tagLinePlaceholder,
  caseTitle,
  caseTitlePlaceHolder,
  caseCategory,
  description,
  descriptionPlaceHolder,
  reminderTitlePlaceHolder,
  selectDate,
  selectDatePlaceHolder,
  clientCnic,
  clientCnicPlaceHolder,
  caseNo,
  caseNoPlaceHolder,
  selectTime,
  selectTimePlaceholder,
  courtPlaceHolder,
  noteTitlePlaceHolder,
  sectionName,
  sectionDescription
};
