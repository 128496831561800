/* eslint-disable @typescript-eslint/no-explicit-any */
import { QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";

import { api } from "services/api";
import { ErrorType } from "services/mapAPIError";
import { ApiMethods } from "static/apis";

export const useAppMutation = (
  endPoint: string,
  method: ApiMethods = ApiMethods.POST,
  refetchQueries?: Array<unknown>,
  onSuccess?: (data: Data) => void
) => {
  const queryClient = useQueryClient();
  const queryKey: QueryKey = refetchQueries ?? [];
  const { data, error, isLoading, mutate, reset } = useMutation(
    ({ data, id, params }: MutateData) =>
      api({ data, endPoint, id, method, params }),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(queryKey);
        refetchQueries &&
          queryClient.setQueryData(queryKey ?? "", (oldQueryData: any) => {
            if (!oldQueryData) return;
            return { data: oldQueryData?.data, oldQueryData };
          });
      },
      onSuccess: (response) => {
        const data = response as Data;
        onSuccess?.(data);
        refetchQueries?.forEach((query?: any) => {
          queryClient.invalidateQueries(query);
          setTimeout(() => reset(), 0);
        });
      },
    }
  );
  const customData = data as Data;
  return {
    data: customData,
    error: error as ErrorType,
    isLoading,
    mutate,
    reset,
  };
};

export interface Data {
  data?: Record<string, any>;
  headers?: Record<string, any>;
  message?: string;
}

interface MutateData {
  data?: Record<string, any>;
  id?: string | number;
  params?: string;
}
