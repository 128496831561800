import { ReactNode, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "react-use";
import { links } from "static/links";

export const AuthContext = createContext<AuthContextData>({
  clearAuth: () => {
    return;
  },
  setAuth: () => {
    return null;
  },
});
export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const [authUser, setAuthUser, clearAuthUser] =
    useLocalStorage<DynamicObject>("user");
  const setAuth = (user: DynamicObject) => {
    setAuthUser(user);
    navigate(links.dashboard, { replace: true });
  };
  const clearAuth = () => {
    clearAuthUser();
    navigate(links.login, { replace: true });
  };
  return (
    <AuthContext.Provider value={{ authUser, clearAuth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
export interface AuthContextData {
  clearAuth: () => void;
  authUser?: DynamicObject;
  setAuth: (authUser: DynamicObject) => void;
}

export const useAuthContext = () => useContext(AuthContext);
