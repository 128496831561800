import { Menu, MenuItem, Typography } from '@mui/material';
import React, { memo } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const AppMenu = ({
  id,
  open,
  menuId,
  handleClick,
  anchorEl,
  handleClose,
  title
}: AppMenuProps) => {
  return (
    <>
      <Typography
        variant='body2'
        id={id}
        aria-controls={open ? menuId : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        display='flex'
      >
        {title}
        <ArrowDropDownIcon />
      </Typography>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': id
        }}
        PaperProps={{
          style: {
            width: '165px'
          }
        }}
      >
        <MenuItem onClick={handleClose}>Lahore High court</MenuItem>
        <MenuItem onClick={handleClose}>Sessions Court</MenuItem>
        <MenuItem onClick={handleClose}>District Courts Lahore</MenuItem>
      </Menu>
    </>
  );
};

export default memo(AppMenu);

interface AppMenuProps {
  id: string;
  open: boolean;
  menuId: string;
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  title: string;
}
