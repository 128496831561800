import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';

import AppTable from 'components/Common/AppTable';
import { DeleteLawyerIcon } from 'static/icons/DeleteLawyerIcon';
import ConfirmationModal from 'components/Common/ConfirmationModal';
import { EditIcon } from 'static/icons/EditIcon';
import AddSection from './AddSection';
import {
  useDeleteSection,
  useGetSections
} from 'hooks/AdminDashboard/Sections';
import { useDisplayError } from 'hooks/useDisplayError';
import { usePagination } from 'hooks/usePagination';

function AdminSections() {
  const [addSection, setAddSection] = useState(false);
  const [warning, setWarning] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState();
  const [selectedSection, setSelectedSection] = useState<SectionType | null>(
    null
  );
  const displayError = useDisplayError();
  const { page, perPage } = usePagination();

  const classes = useStyles();

  const { list, meta, response } = useGetSections({ page, perPage });

  const sectionsListing = list;

  const error = response.error;
  const loading = response.isLoading;
  displayError(error);

  const handleDeleteSuccess = () => {
    setWarning(false);
  };

  const deleteSection = useDeleteSection({
    onSuccess: handleDeleteSuccess
  });

  const actions = (row: DynamicObject) => {
    return (
      <Stack direction='row' spacing={2}>
        {EditIcon(row, handleAction)}
        {DeleteLawyerIcon(row, handleAction)}
      </Stack>
    );
  };
  const handleAction = (row: DynamicObject, action: string) => {
    if (action === 'delete') {
      setWarning(true);
      setSelectedRowId(row?.id);
    } else if (action === 'edit') {
      setSelectedSection(row as SectionType);
      setAddSection(true);
    }
  };
  const handleAddSection = () => {
    setAddSection(true);
  };
  const handleAddSectionClose = () => {
    setSelectedSection(null);
    setAddSection(false);
  };
  const handleCancelConfirmation = () => {
    setWarning(false);
  };

  const handleDeleteSection = () => {
    deleteSection.mutate({
      id: selectedRowId
    });
  };

  return (
    <>
      <ConfirmationModal
        title='Are You Sure?'
        description='Are you sure you want to delete this section from the 
        list?'
        open={warning}
        actionButtons={
          <>
            <Stack
              direction='row'
              justifyContent='flex-end'
              marginTop={4}
              spacing={2}
            >
              <Button
                variant='outlined'
                className={classes.cancelBtn}
                onClick={handleCancelConfirmation}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                className={classes.deleteBtn}
                onClick={handleDeleteSection}
              >
                Delete
              </Button>
            </Stack>
          </>
        }
      />
      {addSection && (
        <AddSection
          open={addSection}
          handleClose={handleAddSectionClose}
          selectedSection={selectedSection}
        />
      )}
      <Grid container marginTop='10px' padding='0px 31px' spacing={5}>
        <Grid item xs={12}>
          <Stack justifyContent='space-between' direction='row'>
            <Typography className={classes.mainHeading}>Sections</Typography>
            <Button
              variant='contained'
              startIcon={<AddIcon />}
              className={classes.addCaseBtn}
              onClick={handleAddSection}
            >
              Add New Section
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.addSectionContainer}>
            <AppTable
              tableHead={tableHead}
              actionsHeading='Action'
              isLoading={loading}
              meta={meta}
              actions={actions}
              rows={sectionsListing}
              rowIdentifiers={rowIdentifiers}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default AdminSections;

const rowIdentifiers = ['name'];

const tableHead = [{ key: 1, title: 'Section Name' }];

const useStyles = makeStyles({
  mainHeading: {
    fontFamily: 'Poppins !important',
    fontStyle: 'normal !important',
    fontWeight: '500 !important',
    fontSize: '20px !important',
    lineHeight: '91.5% !important',
    color: '#252733 !important'
  },
  addCaseBtn: {
    background: '#1976D2 !important',
    fontSize: '14px !important',
    fontFamily: 'Roboto !important'
  },
  addSectionContainer: {
    width: '100%',
    padding: '45px',
    background: '#FFFFFF',
    boxShadow:
      '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 0px 16px rgba(0, 0, 0, 0.08)',
    borderRadius: '4px'
  },
  cancelBtn: {
    border: '1px solid #DBDDE0',
    borderRadius: '4px',
    color: '#52575C',
    fontSize: '14px'
  },
  deleteBtn: {
    background: '#B50000 !important',
    borderRadius: '4px',
    color: '#FFFFFF',
    fontSize: '14px'
  }
});

export interface SectionType {
  name: string;
  description: string;
  id: number;
}
