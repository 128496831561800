import { useReducer } from "react";
import { CssBaseline, ThemeProvider, useTheme } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { SnackbarProvider } from "notistack";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Routes from "routes";
import { AppTheme } from "styles/AppTheme";
import { UserProvider } from "userContext/userContext";
import { initialState, userReducer } from "userContext/userReducer";
import "styles/App.css";

function App() {
  const [state, dispatch] = useReducer(userReducer, initialState);
  const queryClient = new QueryClient();
  const theme = useTheme();

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={AppTheme(theme)}>
        <CssBaseline />
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          preventDuplicate={true}
        >
          <UserProvider value={[state, dispatch]}>
            <Routes />
          </UserProvider>
        </SnackbarProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
}

export default App;
