import axios, { AxiosError } from "axios";

export const mapAPIError: Err = (err: unknown) => {
  const error = err as Error | AxiosError;
  const custom = { message: "Something went wrong" };

  if (!axios.isAxiosError(error)) return custom;

  if (error.response) {
    if (error.response.status === 401) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      window.location.reload();
    }
    const err = error?.response?.data as {
      error?: string | DynamicObject;
      errors?: Array<string> | string;
      message?: string;
      data?: DynamicObject;
    };

    if (err?.error) {
      if (Array.isArray(err.error)) return { message: err.error[0] };
      else if (typeof err.error !== "string" && err?.error?.error)
        return { message: err?.error?.error };
      else if (typeof err.error === "string") return { message: err.error };
    }

    if (err?.message && err?.data) {
      return { data: err.data, message: err.message };
    } else if (err?.message && err?.error) {
      return { message: err?.message };
    } else if (err?.message) {
      return { message: err?.message };
    }
    return custom;
  } else if (error.request) {
    return { message: error.message } || custom;
  } else {
    return { message: error.message };
  }
};

type Err = (err: unknown) => ErrorType;

export type ErrorType = {
  data?: DynamicObject;
  message: string;
  error?: string;
};
