import React, { Suspense } from "react";
import { memo } from "react";
import { Box, Theme } from "@mui/material";
import { Navigate, Outlet } from "react-router-dom";
import AuthHeader from "components/shared/AuthHeader";
import { makeStyles } from "@mui/styles";
import { useAuthContext } from "userContext/AuthContext";

const Auth = () => {
  const classes = useStyles();
  const { authUser } = useAuthContext();
  if (authUser && authUser.id) return <Navigate to="/" />;
  return (
    <Suspense fallback="Loading.....">
      <Box className={classes.MainAuthContainer}>
        <AuthHeader />
        <Outlet />
      </Box>
    </Suspense>
  );
};

export default memo(Auth);

const useStyles = makeStyles((theme: Theme) => ({
  MainAuthContainer: {
    margin: 0,
    minHeight: "100vh",
  },
}));
