import { memo, Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";

import Layout from "components/Layout";
import { links } from "static/links";
import { useAuthContext } from "userContext/AuthContext";

const PrivateOutlet = () => {
  const { authUser } = useAuthContext();
  return authUser && authUser.id ? (
    <Suspense fallback="loading....">
      {authUser.role === 2 ? (
        <Layout>
          <Outlet />
        </Layout>
      ) : (
        <Navigate to={links.notFound} />
      )}
    </Suspense>
  ) : (
    <Navigate to={links.login} />
  );
};
export default memo(PrivateOutlet);
